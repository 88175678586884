import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import './Contact.css'; // Archivo CSS para estilos adicionales

const Contact = () => {
    return (
        <section id="contact" className="section bg-light">
            <Container>
                <div className="contact-wrapper">
                    <h2 className="section-title">Contacto</h2>
                    <p className="section-subtitle">¡Ponte en contacto conmigo!</p>
                    <Row>
                        <Col md={6} className="contact-info">
                            <div className="contact-item">
                                <FaPhone className="contact-icon" />
                                <a href="tel:+573163449117" className="contact-link">+573163449117</a>
                            </div>
                            <div className="contact-item">
                                <FaEnvelope className="contact-icon" />
                                <a href="mailto:luisfoguz1198@gmail.com" className="contact-link">luisfoguz1198@gmail.com</a>
                            </div>
                            <div className="social-links">
                                <a href="https://facebook.com" className="contact-link"><FaFacebook className="contact-icon" /></a>
                                <a href="https://twitter.com" className="contact-link"><FaTwitter className="contact-icon" /></a>
                                <a href="https://linkedin.com" className="contact-link"><FaLinkedin className="contact-icon" /></a>
                            </div>
                        </Col>
                        <Col md={6} className="contact-schedule">
                            <div className="schedule-item">
                                <p>Agenda una reunión conmigo:</p>
                                <a href="https://calendly.com/luisfoguz1198" className="btn btn-primary">Programar una reunión</a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    );
}

export default Contact;
