import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col md={4} className="footer-section">
                        <h5>Acerca de mí</h5>
                        <p>
                            Soy un desarrollador apasionado por crear soluciones innovadoras y eficientes. Dedicado a aprender y mantenerme actualizado con las últimas tendencias en desarrollo web.
                        </p>
                    </Col>
                    <Col md={4} className="footer-section">
                        <h5>Escríbenos</h5>
                        <p>Correo: luisfoguz1198@gmail.com</p>
                        <p>Contacto: +573163449117</p>
                    </Col>
                    <Col md={4} className="footer-section">
                        <h5>Sígueme</h5>
                        <div className="social-icons">
                            <a href="https://github.com/webdesigner98everyone" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                            <a href="https://www.linkedin.com/in/luis-alberto-forero-guzman-840647121" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-3">
                        <p>&copy; {new Date().getFullYear()} Luis Forero. Reservados todos los derechos..</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
