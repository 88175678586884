import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import cchart from '../img/cchart.png'; 
import java from '../img/Java.png';
import frame from '../img/frame.png';
import go from '../img/golang.png';
import mysql from '../img/mysql.png';
import gx from '../img/gx.jpg';
import scrum from '../img/srcum.jpg';
import Angular from '../img/Angular.png';
import laravel from '../img/laravel.png';
import jasper from '../img/jasper.jpg';
import crystal from '../img/crystal.png';
import './Certificates.css'; // Archivo CSS para estilos adicionales

const Certificates = () => {
    return (
        <section id="certificates" className="section">
            <Container>
                <h2>Certificaciones</h2>
                <Row>
                    <Col md={4}>
                        <div className="certificates-card">
                            <Card className="card">
                                <Card.Img variant="top" src={cchart} />
                                <Card.Body className="card-body">
                                    <Card.Title>C#</Card.Title>
                                    <Card.Text className="certificates-description">
                                        lenguaje de programación multiparadigma <br /><br />
                                        Año Cursado: 2015
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="certificates-card">
                            <Card className="card">
                                <Card.Img variant="top" src={java} />
                                <Card.Body className="card-body">
                                    <Card.Title>Master Completo en Java</Card.Title>
                                    <Card.Text className="certificates-description">
                                        Curso Java de cero con las mejores practicas POO,
                                        Java EE9, CDI, JPA, Web Services <br /><br />
                                        Año Cursado: 2024
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="certificates-card">
                            <Card className="card">
                                <Card.Img variant="top" src={frame} />
                                <Card.Body className="card-body">
                                    <Card.Title>Desarrollo App Moviles Hibridas</Card.Title>
                                    <Card.Text className="certificates-description">
                                        habilidades en la creación de aplicaciones móviles híbridas utilizando frameworks modernos 
                                        como Angular, Laravel e Ionic. <br /><br />
                                        Año Cursado: 2019
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="certificates-card">
                            <Card className="card">
                                <Card.Img variant="top" src={go} />
                                <Card.Body className="card-body">
                                    <Card.Title>Golang</Card.Title>
                                    <Card.Text className="certificates-description">
                                        Un lenguaje de programación creado por Google conocido por su simplicidad, eficiencia y capacidad 
                                        para manejar aplicaciones concurrentes y de alto rendimiento. <br /><br />
                                        Año Cursado: 2021
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="certificates-card">
                            <Card className="card">
                                <Card.Img variant="top" src={mysql} />
                                <Card.Body className="card-body">
                                    <Card.Title>BD Mysql</Card.Title>
                                    <Card.Text className="certificates-description">
                                        comprensión completa de MySQL, uno de los sistemas de gestión de bases de datos relacionales 
                                        desde los conceptos básicos hasta técnicas avanzadas para diseñar, 
                                        implementar y administrar bases de datos eficientes y seguras.
                                        <br /><br />
                                        Año Cursado: 2021
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="certificates-card">
                            <Card className="card">
                                <Card.Img variant="top" src={gx} />
                                <Card.Body className="card-body">
                                    <Card.Title>Genexus Evolution 3</Card.Title>
                                    <Card.Text className="certificates-description">
                                        Comprensión profunda del manejo y administración de bases de datos utilizando GeneXus 
                                        Evolution 3, una herramienta avanzada de desarrollo que facilita la creación de 
                                        aplicaciones conocimiento en modelar, gestionar, y optimizar bases de datos,
                                        <br /><br />
                                        Año Cursado: 2017
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="certificates-card">
                            <Card className="card">
                                <Card.Img variant="top" src={scrum} />
                                <Card.Body className="card-body">
                                    <Card.Title>Certificacion Scrum</Card.Title>
                                    <Card.Text className="certificates-description">
                                        comprensión integral del marco de trabajo Scrum, una metodología ágil que facilita la 
                                        gestión y ejecución de proyectos de manera eficiente.
                                        <br /><br />
                                        Año Cursado: 2019
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="certificates-card">
                            <Card className="card">
                                <Card.Img variant="top" src={Angular} />
                                <Card.Body className="card-body">
                                    <Card.Title>Fundamentos de Angular</Card.Title>
                                    <Card.Text className="certificates-description">
                                        Conocimientos solidos para construir aplicaciones con Angular.
                                        Conocimientos sobre componentes, data binding, directivas, servicios y routing.
                                        <br /><br />
                                        Año Cursado: 2021
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="certificates-card">
                            <Card className="card">
                                <Card.Img variant="top" src={laravel} />
                                <Card.Body className="card-body">
                                    <Card.Title>Certificacion Laravel 8</Card.Title>
                                    <Card.Text className="certificates-description">
                                        Desarrollar aplicaciones web utilizando el framework Laravel.
                                        Manejar rutas, controladores y vistas.
                                        Trabajar con bases de datos usando Eloquent ORM.
                                        <br /><br />
                                        Año Cursado: 2021
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="certificates-card">
                            <Card className="card">
                                <Card.Img variant="top" src={jasper} />
                                <Card.Body className="card-body">
                                    <Card.Title>Creacion de reportes con JasperReport</Card.Title>
                                    <Card.Text className="certificates-description">
                                        Conocimientos para generar reportes simples y complejos usando Jasper Report
                                        <br /><br />
                                        Año Cursado: 2023
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="certificates-card">
                            <Card className="card">
                                <Card.Img variant="top" src={crystal} />
                                <Card.Body className="card-body">
                                    <Card.Title>SAP Crystal Reports consultor</Card.Title>
                                    <Card.Text className="certificates-description">
                                        Conocimientos para generar reportes simples y complejos usando Crystal Reports
                                        <br /><br />
                                        Año Cursado: 2023
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Certificates;
