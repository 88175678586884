import React, { useState } from 'react';
import { Navbar, Nav, Form, FormControl, Button, Container, ListGroup } from 'react-bootstrap';
import './Navbar.css'; // Archivo CSS para estilos adicionales
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-scroll';

const MyNavbar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showMenu, setShowMenu] = useState(false); // Estado para controlar la visibilidad del menú

    const sections = [
        { id: 'home', name: 'Inicio', content: 'Bienvenido a mi sitio web. Aquí encontrarás información sobre mí y mis proyectos.' },
        { id: 'about', name: 'Quién Soy', content: 'Soy un desarrollador apasionado por la creación de soluciones innovadoras y eficientes. Con una sólida formación en tecnología y una dedicación constante al aprendizaje, me esfuerzo por mantenerme actualizado con las últimas tendencias y herramientas en el desarrollo web.' },
        { id: 'projects', name: 'Proyectos', content: 'He trabajado en una variedad de proyectos, desde aplicaciones web hasta soluciones móviles. Algunos de mis proyectos destacados incluyen...' },
        { id: 'certificates', name: 'Certificados', content: 'Aquí puedes ver los certificados que he obtenido a lo largo de mi carrera.' },
        { id: 'contact', name: 'Contacto', content: 'Si deseas contactarme, puedes hacerlo a través de mi número de teléfono o correo electrónico.' },
    ];

    const handleSearch = (event) => {
        event.preventDefault();
        if (searchTerm.trim() === '') {
            setSearchResults([]);
            return;
        }

        const results = sections.filter(section =>
            section.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            section.content.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(results);

        // Ocultar el menú hamburguesa cuando se busca
        if (showMenu) {
            setShowMenu(false);
        }
    };

    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg" fixed="top" className="py-3">
                <Container>
                    <Navbar.Toggle 
                        aria-controls="basic-navbar-nav" 
                        onClick={() => setShowMenu(!showMenu)} // Alternar el menú al hacer clic
                    />
                    <Navbar.Collapse id="basic-navbar-nav" in={showMenu}>
                        <Nav className="me-auto">
                            {sections.map(section => (
                                <Nav.Link key={section.id}>
                                    <Link to={section.id} smooth duration={500} className="nav-link">
                                        {section.name}
                                    </Link>
                                </Nav.Link>
                            ))}
                        </Nav>
                        <div className="d-flex align-items-center">
                            <Form className="d-flex align-items-center" onSubmit={handleSearch}>
                                <FormControl
                                    type="search"
                                    placeholder="Buscar"
                                    className="me-2"
                                    aria-label="Buscar"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <Button variant="outline-success" type="submit" className="me-3">
                                    <FaSearch />
                                </Button>
                            </Form>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {searchResults.length > 0 && (
                <Container className="search-results-container mt-5">
                    <ListGroup>
                        {searchResults.map((result, index) => (
                            <ListGroup.Item key={index} className="search-result-item">
                                <Link to={result.id} smooth duration={500} className="search-result-link">
                                    {result.name}
                                </Link>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Container>
            )}
        </>
    );
};

export default MyNavbar;
