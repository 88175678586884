import React from 'react';
import './Home.css'; // Asegúrate de importar el archivo CSS
import { Container } from 'react-bootstrap';

const Home = () => {
    return (
        <div className="home-section">
            <Container className="text-center">
                <h1>Bienvenido a mi página</h1>
                <p>Esta es una breve introducción sobre mí.</p>
            </Container>
        </div>
    );
}

export default Home;
