import React from 'react';
import MyNavbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Certificates from './components/Certificates';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaWhatsapp } from 'react-icons/fa'; // Importa el ícono


const App = () => {
    return (
        <>
            <MyNavbar />
            <Home />
            <About />
            <Projects />
            <Certificates />
            <Contact />
            <Footer />

            <a href="https://api.whatsapp.com/send?phone=573163449117" className="whatsapp-float" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp size={24} color="white" />
            </a>
        </>
    );
}

export default App;
