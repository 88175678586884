import React from 'react';
import { Container, Row, Col, Image, ProgressBar } from 'react-bootstrap';
import img3 from '../img/img3.jpg'; // Importa la imagen directamente
import './About.css'; // Archivo CSS para estilos adicionales

const About = () => {
    const skills = [
        { skill: 'PHP', percent: 85 },
        { skill: 'CSS', percent: 100 },
        { skill: 'JavaScript (Angular, Node)', percent: 80 },
        { skill: 'React', percent: 85 },
        { skill: 'Golang', percent: 75 },
        { skill: 'Java', percent: 75 },
        { skill: 'Crystal Report', percent: 100 },
        { skill: 'Jasper Report', percent: 90 },
    ];

    return (
        <section id="about" className="section bg-light">
            <Container>
                <Row className="align-items-center mb-5">
                    <Col md={6} className="mb-4 mb-md-0">
                        <Image src={img3} rounded fluid className="shadow-lg about-image" />
                    </Col>
                    <Col md={6}>
                        <h2 className="section-title">Quién Soy</h2>
                        <p className="lead">
                            Soy un desarrollador apasionado por la creación de soluciones innovadoras y eficientes.
                            Con una sólida formación en tecnología y una dedicación constante al aprendizaje,
                            me esfuerzo por mantenerme actualizado con las últimas tendencias y herramientas en el desarrollo web.
                        </p>
                        <p>
                            Mi experiencia abarca una variedad de proyectos, desde aplicaciones web hasta soluciones móviles,
                            siempre con un enfoque en la calidad y la satisfacción del usuario.
                            Estoy comprometido con la entrega de productos que no solo cumplan, sino que superen las expectativas.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 className="skills-title">Mis Habilidades</h3>
                        <div className="skills">
                            {skills.map((item, index) => (
                                <div key={index} className="skill mb-4">
                                    <div className="d-flex justify-content-between mb-1">
                                        <span>{item.skill}</span>
                                        <span>{item.percent}%</span>
                                    </div>
                                    <ProgressBar now={item.percent} />
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default About;
