import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import go from '../img/golang.jpg'; // Importa la imagen directamente
import html from '../img/html.jpg';
import java from '../img/Java.png';
import react from '../img/React.jpg';
import js from '../img/js.png';
import './Projects.css'; // Archivo CSS para estilos adicionales

const Projects = () => {
    return (
        <section id="projects" className="section">
            <Container>
                <h2>Mis Proyectos</h2>
                <Row>
                    <Col md={12}>
                        <div className="project-banner">
                            <Card className="banner-card">
                                <Row noGutters>
                                    <Col md={4}>
                                        <Card.Img variant="top" src={go} />
                                    </Col>
                                    <Col md={8}>
                                        <Card.Body className="banner-body">
                                            <Card.Title>Twittor-Go</Card.Title>
                                            <Card.Text className="project-description">
                                                Este proyecto, desarrollado en Golang, está diseñado para el backend y aprovecha la robustez de MongoDB para el almacenamiento de datos y la eficiencia de Postman para la gestión de API. <br /><br />
                                                Descubre más detalles en: <br />
                                                <a href="https://github.com/webdesigner98everyone/twittor.git" target="_blank" rel="noopener noreferrer">Twittor</a>
                                            </Card.Text>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="project-banner">
                            <Card className="banner-card">
                                <Row noGutters>
                                    <Col md={4}>
                                        <Card.Img variant="top" src={html} />
                                    </Col>
                                    <Col md={8}>
                                        <Card.Body className="banner-body">
                                            <Card.Title>SneakerStore</Card.Title>
                                            <Card.Text className="project-description">
                                                Sitio web desarrollado con HTML, CSS y JavaScript, utilizando una base de datos MySQL para la gestión de productos. Esta plataforma ofrece una experiencia de compra en línea intuitiva y eficiente para calzado deportivo. <br /><br />
                                                Descubre más detalles en: <br />
                                                <a href="https://github.com/webdesigner98everyone/SneakerStore.git" target="_blank" rel="noopener noreferrer">SneakerStore</a>

                                            </Card.Text>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="project-banner">
                            <Card className="banner-card">
                                <Row noGutters>
                                    <Col md={4}>
                                        <Card.Img variant="top" src={java} />
                                    </Col>
                                    <Col md={8}>
                                        <Card.Body className="banner-body">
                                            <Card.Title>StopMania-Java</Card.Title>
                                            <Card.Text className="project-description">
                                                Proyecto desarrollado en Java, con una interfaz gráfica en Javax, que recrea el clásico juego de mesa "Stop". Este juego interactivo está diseñado para ofrecer una experiencia de entretenimiento envolvente y competitiva. <br /><br />
                                                Descubre más detalles en: <br />
                                                <a href="https://github.com/webdesigner98everyone/StopMania-Java.git" target="_blank" rel="noopener noreferrer">StopMania-Java</a>

                                            </Card.Text>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="project-banner">
                            <Card className="banner-card">
                                <Row noGutters>
                                    <Col md={4}>
                                        <Card.Img variant="top" src={react} />
                                    </Col>
                                    <Col md={8}>
                                        <Card.Body className="banner-body">
                                            <Card.Title>Blog Personal</Card.Title>
                                            <Card.Text className="project-description">
                                                Mi sitio web personal, construido con React, está diseñado para compartir información relevante sobre mí y mis proyectos. Esta plataforma facilita a los usuarios contactarse conmigo de manera sencilla y eficiente. <br /><br />
                                                Descubre más detalles en: <br />
                                                <a href="https://github.com/webdesigner98everyone/mi-pagina-personal.git" target="_blank" rel="noopener noreferrer">Blog-Personal</a>

                                            </Card.Text>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="project-banner">
                            <Card className="banner-card">
                                <Row noGutters>
                                    <Col md={4}>
                                        <Card.Img variant="top" src={js} />
                                    </Col>
                                    <Col md={8}>
                                        <Card.Body className="banner-body">
                                            <Card.Title>Ruleta en JS</Card.Title>
                                            <Card.Text className="project-description">
                                                El Aplicativo de Ruleta Interactiva es una solución web avanzada diseñada para proporcionar una experiencia de juego emocionante y envolvente.
                                                Más detalles en: <br />
                                                <a href="https://github.com/webdesigner98everyone/ruleta.git" target="_blank" rel="noopener noreferrer">Ruleta</a>
                                            </Card.Text>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Projects;
